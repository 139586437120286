import React, { useRef } from 'react';
import {useState,useEffect} from 'react';
import { Link, Redirect } from 'react-router-dom';
import ShowImage from './ShowImage';
import Moment from 'react-moment';
import {addItem, itemTotal,updateItem,removeItem} from './CartHelper'

const Card = ({product,viewProductButton = true,viewAddToCart = true ,cartUpdate = false,
    showRemoveProductButton = false,location = '/',
    setRun = f => f,
    run = undefined}) => {
    const [redirect,setRedirect] = useState(false)
    const [count, setCount] = useState(product.count);

    const handleChange = productId => event => {
        setRun(!run); // run useEffect in parent Cart
        setCount(event.target.value < 1 ? 1 : event.target.value);
        if (event.target.value >= 1) {
          updateItem(productId, event.target.value);
        }
      };
    const showProductButton = () => {
        return(
            <Link to = {`/product/${product._id}`}  className = "pr-3">
            {
                viewProductButton && (
                    <button className = "btn btn-outline-dark" style = {{marginRight : "10px"}} >
                      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-collection-fill" viewBox="0 0 16 16">
  <path d="M0 13a1.5 1.5 0 0 0 1.5 1.5h13A1.5 1.5 0 0 0 16 13V6a1.5 1.5 0 0 0-1.5-1.5h-13A1.5 1.5 0 0 0 0 6v7zM2 3a.5.5 0 0 0 .5.5h11a.5.5 0 0 0 0-1h-11A.5.5 0 0 0 2 3zm2-2a.5.5 0 0 0 .5.5h7a.5.5 0 0 0 0-1h-7A.5.5 0 0 0 4 1z"/>
</svg>
                      &nbsp;View Product
                      </button>
                )
            }
            
        </Link>
        );
    }
    /********************************************************** */
    const showAddtoCartButton = () => {
        return(
            
                viewAddToCart  && (product.Quentity > 0) && (<button className = "btn btn-outline-dark" onClick = {onClickHandler} >
                                                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-cart-plus mb-1 " viewBox="0 0 16 16">
                    <path d="M.5 1a.5.5 0 0 0 0 1h1.11l.401 1.607 1.498 7.985A.5.5 0 0 0 4 12h1a2 2 0 1 0 0 4 2 2 0 0 0 0-4h7a2 2 0 1 0 0 4 2 2 0 0 0 0-4h1a.5.5 0 0 0 .491-.408l1.5-8A.5.5 0 0 0 14.5 3H2.89l-.405-1.621A.5.5 0 0 0 2 1H.5zM6 14a1 1 0 1 1-2 0 1 1 0 0 1 2 0zm7 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0zM9 5.5V7h1.5a.5.5 0 0 1 0 1H9v1.5a.5.5 0 0 1-1 0V8H6.5a.5.5 0 0 1 0-1H8V5.5a.5.5 0 0 1 1 0z"/>
                </svg>
                &nbsp;
                  Add to cart
                  </button>)
        );
    }
    const showRemoveButton = showRemoveProductButton => {
        return (
            showRemoveProductButton && (
            <button
              onClick={() => {
                removeItem(product._id);
                setRun(!run); // run useEffect in parent Cart
              }}
              className="btn btn-outline-danger "
            >
              Remove Product
            </button>
          )
        );
      };
    const showStock = quantity => {
        return quantity > 0 ? (
              <span className="badge alert-success " style = {{fontSize : "18px"}}>In Stock</span>
        ) : (
        <span className="badge alert-danger" style = {{fontSize : "18px"}}>Out of Stock </span>
        );
    };
    const onClickHandler = () => {
        addItem(product, () => {
            setRedirect(true);
        })
    }
    const RedirectTo = () =>{
        if(redirect)
        return(
            <Redirect to = {location}></Redirect>
        );
    }
    const showCartUpdateOptions = cartUpdate => {
        return (
          cartUpdate && (
            <div>
              <div className="input-group mb-3 mt-3">
                <div className="input-group-prepend">
                  <span className="input-group-text">Adjust Quantity</span>
                </div>
                <input type="number" className="form-control" value={count} onChange={handleChange(product._id)}/>
              </div>
            </div>
          )
        );
      };
      const showDescription = () => {
        if(viewProductButton)
        {
          return(
            <p className = "black-10" style = {{textAlign :"left",height : "150px"}}>{ product.description ? product.description.substring(0, 200) : '' } ....... </p>
          );
        }
        else
        {
          return(
            <p className = "black-10">{ product.description ? product.description : '' } </p>
          );
        }
      }
    return(
        
        <div className = "card">
          <Link to = {`/product/${product._id}`}  className = "pr-3"  style = {{textDecoration : "none " ,color : "black"}}>
            <div className = "name text-center">{product.name}</div>
          </Link>
            <div className = "card-body">
              <Link to = {`/product/${product._id}`}  className = "pr-3"  style = {{textDecoration : "none " ,color : "black"}}>
                <ShowImage item = {product} url = "products" />
              </Link>
                <div style = {{textAlign:"center"}}>
                {showStock(product.Quentity)}
                </div>
                <hr></hr>
                {showDescription()}
                <p className = "black-9">Cartegory: {product.category && product.category.name}</p>
                <p className = "black-8">{product.price} EGP</p>
                <p className = "black-7">
                    Add on :  <Moment format = "Do MMMM  YYYY">{product.createdAt}</Moment>
                </p>
                <div style = {{textAlign : "center"}} className = "mt-2 mb-2">
                {showProductButton()}
                {showAddtoCartButton()}
                {showRemoveButton(showRemoveProductButton) }
                {showCartUpdateOptions(cartUpdate)}
                </div>
                {RedirectTo()}
                
                
            </div>
        </div>
    );
}
export default Card;