import { useState, useEffect } from 'react';
import { getCategory,list } from './apiCore';
import Card from './Card';

const Search = () => {
    const [data,setData] = useState({
        categories : [],
        category:'',
        search: '',
        results : [],
        searched : false 

    });
    const {categories,category,search ,results ,searched } = data; 
    const loadcategories = () => 
    {
        getCategory().then(data => {
            if(data.error)
            {
                console.log(data.error)
            }
            else
            {
                setData({...data,categories:data,searched:false})
            }
        })
    }
    useEffect(() => {
        loadcategories();
    },[]) 
    const onSubmitHandler = (e) => 
    {
        e.preventDefault();

        searchData();
        
        
    }
    const searchData = () => {
        if(search)
        {
            list({search:search,category:category}).then(Responsen => {
                if(Responsen.error)
                {
                    console.log(Response.error)
                }
                else
                {
                    setData({...data,results:Responsen,searched:true})
                }
            })
        }
    }
    const onHandelChange = name => event => 
    {
        setData({...data,[name] : event.target.value})
        
    }
    const searchedProducts = (results=[]) =>
    {
        return(
            <div className ='row'>
                {results.map((p,i) => (
                    <Card key = {i} product = {p}/>
                ))}
            </div>
        );
    }
    const searchedProductsResults = (searched,results) => 
    {
        
        return (
            <h2>{results ? 'Found' : 'Not Found'}</h2>
        );
    }
    const SearchForm = () => (
        <form onSubmit={onSubmitHandler}>
            <span className="input-group-text">
                <div className="input-group input-group-lg">
                    <div className="input-group-prepend">
                        <select
                            className="btn mr-2"
                            onChange={onHandelChange("category")}
                        >
                            <option value="All">All</option>
                            {categories.map((c, i) => (
                                <option key={i} value={c._id}>
                                    {c.name}
                                </option>
                            ))}
                        </select>
                    </div>

                    <input
                        type="search"
                        className="form-control"
                        onChange={onHandelChange("search")}
                        placeholder="Search by name"
                    />
                </div>
                <div
                    className="btn input-group-append"
                    style={{ border: "none" }}
                >
                                        <button className="input-group-text">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-search" viewBox="0 0 16 16">
  <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z"/>
</svg>
                    </button>
                </div>
            </span>
        </form>
    )
    return(
        <div className = 'row ml-2 mr-2'>
            <div className = "container">
                {SearchForm()}
            </div>
            <div className = "container-fluid row ">
                {searchedProducts(results)}
            </div>
        </div>
    ); 

}
export default Search