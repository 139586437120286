import React, { useState, useEffect } from "react";
import Layout from "../core/Layout";
import { Link } from "react-router-dom";
import { getPurchaseHistory } from "./apiUser";
import { isAuthenticate } from "./auth";
import moment from "moment";
import UserLinks from "./UserLinks";
import { useHistory } from "react-router-dom";
const Dashboard = () => {
    const myHistory = useHistory();
    const [history, setHistory] = useState([]);
    const {
        user: { _id, name, email, role }
    } = isAuthenticate();
    const token = isAuthenticate().token;
    const userId = isAuthenticate().user._id;
        console.log(userId)
    const init = (userId, token) => {

        getPurchaseHistory(userId, token).then(data => {
            if (data.error) {
                console.log(data.error);
            } else {
                setHistory(data);
            }
        });
    };

    useEffect(() => {
        init(_id, token);
    }, []);



    const userInfo = () => {
        return (
            <div className="card mb-5">
                <h3 className="card-header">User Information</h3>
                <ul className="list-group">
                    <li className="list-group-item">{name}</li>
                    <li className="list-group-item">{email}</li>
                    <li className="list-group-item">
                        {role === 1 ? "Admin" : "Registered User"}
                    </li>
                </ul>
            </div>
        );
    };
    const viewProductHandler = (e,orderId,Product) =>{
        e.preventDefault();
        myHistory.push({
            pathname :`/user/dashboard/orders/${orderId}`,
            state: { products: Product }
        });
    }
    const purchaseHistory = history => {
        return (
            <div className="card mb-5">
                <h3 className="card-header">Order history</h3>
                    <div className = "table-responsive">
                        <table className = "table">
                            <thead>
                                <tr>
                                <th scope="col">#</th>
                                <th scope="col">Status</th>
                                <th scope="col">Amount</th>
                                <th scope="col">Date</th>
                                <th scope="col">Address</th>
                                <th scope="col">Details</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    history.map((o,oIndex) => {
                                        return(
                                            <tr>
                                                <th scope="row">{oIndex}</th>
                                                <td>{o.status}</td>
                                                <td>{o.amount}</td>
                                                <td>{moment(o.createdAt).format("Do MMMM  YYYY, hh:mm a")}</td>
                                                <td>{o.address}</td>
                                                <td>
                                <button className = "btn btn-secondary" onClick = {e => viewProductHandler(e,o._id,o.products)}>
                                        View
                                </button>
                            </td>
                                            </tr>
                                        );
                                    })
                                }
                            </tbody>

                        </table>

                    </div>
            </div>
        );
    };

    return (
        <Layout
            title="Dashboard"
            discreption={`Welcome, ${name}!`}
            className="container-fluid"
        >
            <div className="row">
                <div className="col-12 col-lg-4">
                    <UserLinks></UserLinks>
                </div>
                <div className="col-12 col-lg-8">
                    {userInfo()}
                    {purchaseHistory(history)}
                </div>
            </div>
        </Layout>
    );
};

export default Dashboard;