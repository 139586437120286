import Layout from './../core/Layout';
import UserLinks from './UserLinks';
import { isAuthenticate } from "./auth";
const OrderUser = (props) => {
    const {
        user: { _id, name, email, role }
    } = isAuthenticate();
    const userInfo = () => {
        return (
            <div className="card mb-5">
                <h3 className="card-header">User Information</h3>
                <ul className="list-group">
                    <li className="list-group-item">{name}</li>
                    <li className="list-group-item">{email}</li>
                    <li className="list-group-item">
                        {role === 1 ? "Admin" : "Registered User"}
                    </li>
                </ul>
            </div>
        );
    };
    return (
        <Layout
            title="Dashboard"
            discreption={`Welcome, ${name}!`}
            className="container-fluid"
        >
            <div className="row">
                <div className="col-3">
                    <UserLinks></UserLinks>
                </div>
                <div className="col-9">
                    {userInfo()}
                <div className="card mb-5">
                <h3  className="card-header">
                Total products in the order are : {props.location.state.products.length}
                </h3>
                <br></br>
                <div className = "table-responsive">
                <table className = "table ">
                <thead>
                        <tr>
                        <th scope="col">#</th>
                        <th scope="col">Product name</th>
                        <th scope="col">Product price</th>
                        <th scope="col">Amount</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            props.location.state.products.map((p,i) => {
                                return(
                                    <tr >
                                    <th scope="row">
                                    {i}
                                    </th>
                                    <td>
                                        {p.name}
                                    </td>
                                    <td>
                                        {p.price}
                                    </td>
                                    <td>
                                        {p.count}
                                    </td>
                                    </tr>
                                );
                            }) 
                        }
                    </tbody>
                </table>
                    
                </div>
            </div>
                </div>
            </div>
        </Layout>
    );
}
export default OrderUser;