import Layout from "../core/Layout";
import AdminLinks from './../user/AdminLinks';

const ProductOrders = (props) => {
    let myProducts = []
    const CheckName = () => {
        if ( props.location.state === undefined)
        {
             myProducts = []
            return false;
        }
        else 
        {
             myProducts = props.location.state.products ;
        }
    }
    return (
        <Layout
        title="Orders"
        discreption={` you can manage all the orders here`}
        className="container-fluid"
    >
        <div className="row">
        <div className = "col-4 col-lg-2 ">
                <AdminLinks></AdminLinks>
        </div>
            <div className="col-md-9 offset-md-1">
                <h1>
                Total products in the order are : {props.location.state !== undefined && props.location.state.products.length}
                </h1>
                <br></br>
                <div className = "table-responsive">
                <table className = "table ">
                <thead>
                        <tr>
                        <th scope="col">#</th>
                        <th scope="col">Product ID</th>
                        <th scope="col">Product name</th>
                        <th scope="col">Product price</th>
                        <th scope="col">Amount</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                              props.location.state !== undefined && props.location.state.products.map((p,i) => {
                                return(
                                    <tr >
                                    <th scope="row">
                                    {i}
                                    </th>
                                    <td>
                                        {p._id}
                                    </td>
                                    <td>
                                        {p.name}
                                    </td>
                                    <td>
                                        {p.price}
                                    </td>
                                    <td>
                                        {p.count}
                                    </td>
                                    </tr>
                                );
                            }) 
                        }
                    </tbody>
                </table>
                    
                </div>
            </div>
        </div>
    </Layout>

    );
}
export default ProductOrders;