import React from 'react';
import { API } from './../config';
const ShowImage = ({item,url,imgWidth,imgHieght}) => 
{
    return(
        <div className = "product-img" >
            <img src = {`${API}/${url}/photo/${item._id}`}  alt ={item.name} className = "img-fluid mb-3"
            style = {{ 
                width: "100%", /* width of container */
                height: "250px", /* height of container */
                objectFit : "cover",
                overflow: "hidden"
        }}
            />
        </div>
    );
}
export default ShowImage;