import { Link } from 'react-router-dom';
const AdminLinks = () => {
    
    return (
        <aside className = "flex-shrink">
            <header className = "card-header"> <strong>Links</strong></header>
            <ul className="list-group" id="list-tab" role="tablist">
            <li className="list-group-item list-group-item-action">
                    <Link to = "/create/category" className="nav-link">Create New Category</Link>
                </li>
                <li className = "list-group-item">
                    <Link to = "/create/product" className="nav-link"> Create  New Product</Link>
                </li>
                <li className = "list-group-item">
                    <Link to = "/admin/orders" className="nav-link"> Orders</Link>
                </li>
                <li className = "list-group-item">
                    <Link to = "/manage/products" className="nav-link"> Manage Products</Link>
                </li>
                <li className="list-group-item text-left">
                    <Link className="nav-link" to="/cart">
                        My Cart
                    </Link>
                </li>
                <li className="list-group-item text-left">
                    <Link className="nav-link" to={`/profile/update`}>
                        Update Profile
                    </Link>
                </li>
            </ul>
        </aside>
    );
}
export default AdminLinks;