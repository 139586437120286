import { useState, useEffect } from 'react';
import { Redirect } from 'react-router-dom';
import { getCategory,list } from './apiCore';
const Search_nav = () => {
    const [data,setData] = useState({
        categories : [],
        category:'',
        search: '',
        results : [],
        searched : false 

    });
    const {category,search ,results ,searched } = data; 
    const loadcategories = () => 
    {
        getCategory().then(data => {
            if(data.error)
            {
                console.log(data.error)
            }
            else
            {
                setData({...data,categories:data,searched:false})
            }
        })
    }
    useEffect(() => {
        const abortController = new AbortController();
        loadcategories();
        abortController.abort()
    },[]) 
    const onSubmitHandler = (e) => 
    {
        e.preventDefault();

        searchData();
        
        
    }
    const searchData = () => {
        if(search)
        {
            list({search:search,category:category}).then(Responsen => {
                if(Responsen.error)
                {
                    console.log(Response.error)
                }
                else
                {
                    setData({...data,results:Responsen,searched:true})
                }
            })
        }
    }
    const onHandelChange = name => event => 
    {
        setData({...data,[name] : event.target.value})
        
    }
    const searchedProducts = (results=[]) =>
    {
        return(
            <Redirect to={{
                pathname: '/shop',
                state: { products: results ,redirected: true },
            }}
    />
        );
    }
    const searchedProductsResults = (searched,results) => 
    {
        if(searched)
        {
            return (
                searchedProducts(results)
            );

        }
    }
    const SearchForm = () => (
        <form onSubmit={onSubmitHandler}>
            <span className="input-group-text bg-dark " style={{border:"none"}}>
                <div className="input-group input-group-sm">
                    <input
                        type="search"
                        className="form-control"
                        onChange={onHandelChange("search")}
                        placeholder="Search by product name"
                        size ="300"
                    />
                </div>
                <div
                    className="btn input-group-append"
                    style = {{border: "none",paddingLeft : "0px",paddingRight : "12px"}}
                >
                <button className="input-group-text" >
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-search" viewBox="0 0 16 16">
  <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z"/>
</svg>
                    </button>
                </div>
            </span>
        </form>
    )
    return(
        <div className = 'row ml-2 mr-2'>
            <div className = "container">
                {SearchForm()}
            </div>
                {searchedProductsResults(searched,results)}
        </div>
    ); 

}
export default Search_nav;