export const FixedPrice = [
    {
        _id:0,
        name: ' any',
        array: []
    },
    {
        _id:1,
        name: ' 0 to 9 EGP',
        array: [0,9]
    },
    {
        _id:2,
        name: ' 10 to 19 EGP',
        array: [10,19]
    },
    {
        _id:3,
        name: ' 20 to 29 EGP',
        array: [20,29]
    },
    {
        _id:4,
        name: ' 30 to 39 EGP',
        array: [30,39]
    },
    {
        _id:5,
        name: ' more than 40 EGP',
        array: [40,2000]
    },
]