import Layout from './Layout';
import { useState, useEffect } from 'react';
import { getItems } from './CartHelper';
import Card from './Card';
import { Link } from 'react-router-dom';
import CheckOut from './CheckOut';
import AdminLinks from './../user/AdminLinks';
import { isAuthenticate } from '../user/auth';
import UserLinks from './../user/UserLinks';
const Cart = () => {
    const [items,setItems] = useState([{}]);
    const [run, setRun] = useState(false);
    useEffect(() => {
    setItems(getItems())   
    },[run])
    const showItems = items => {
        return (
            <div>
                <h2>Your cart has {`${items.length}`} items</h2>
                <hr />
                {items.map((product, i) => (
                    <Card
                        key={i}
                        product={product}
                        viewAddToCart = {false}
                        cartUpdate={true}
                        showRemoveProductButton ={true}
                        setRun={setRun}
                        run = {run}
                        
                    />
                ))}
            </div>
        );
    };
    const noItemsMessage = () => (
        <h2>
            Your cart is empty. <br /> <Link to={{
                pathname: '/shop',
                state: { products: [] ,redirected: true },
            }}>Continue shopping</Link>
        </h2>
    );
    return (
        <Layout
            title="Shopping Cart"
            discreption="Manage your cart items."
            className="container-fluid"
        >
            <div className="row" >   
            <div className = "col-12 col-lg-3 ">
                    {isAuthenticate() && isAuthenticate().user.role !== 0 ? <AdminLinks></AdminLinks> : <UserLinks></UserLinks>}
            </div>
                <div className="col-12 col-lg-6"  >{(items !== null && items.length> 0) ? showItems(items) : noItemsMessage()}</div>
                <div className="col-12 col-lg-3">
                    <h2 className="mb-4">Your cart summary</h2>
                    <hr />
                    <CheckOut products={items} setRun={setRun} run={run}  />
                </div>
            </div>
            <br></br>
            <br></br>
            <br></br>
        </Layout>
    );
};
export default Cart;