import React from 'react';
import Layout from './Layout';
import { getProduct } from './apiCore';
import { useState, useEffect } from 'react';
import Card from './Card';
import { API } from '../config';
import img from './logo.png' 
import BeatLoader from "react-spinners/BeatLoader";
const Home = () => {
const [productBySell,setproductBySell] = useState([])
const [productByArrival,setproductByArrival] = useState([]);
const [error,setError] = useState(false);
const [loading,setLoading] = useState(false)

    const loadProductBySell = () => {
        return(
            getProduct('sold')
            .then(data => {
                if(data.error)
                {
                    setError(data.error)
                }
                else
                {
                    setproductBySell(data)
                }
            })
        );
    }
    const loadProductByArrival = () => {
        return(
            getProduct('createdAt')
            .then(data => {
                if(data.error)
                {
                    setError(data.error)
                }
                else
                {
                    setproductByArrival(data)
                    setLoading(false)
                }
            })
        );
    }
useEffect(() => {
    setLoading(true)
    loadProductBySell();
    loadProductByArrival();
    
},[])
return(
    <div>
    {
        loading 
        ?
        (
            <div className = "splash-screen col">
                <div className = "row-12 " style = {{textAlign : "center",justifyContent : "center",display : "flex" }}>
                <img src={img} alt="ZagSystems" width = "400" height = "100" style = {{marginTop : "40vh"}}/>
                </div>
                <div className = "row-12 " style = {{textAlign : "center",justifyContent : "center" ,display : "flex"}}>
                <BeatLoader
                loading={loading}  margin={10} size ={30} />
                </div>
            </div>
        )
        :
        (   <Layout title ="WELCOME TO ZAGSYSTEMS" discreption = "IOT, EMBEDED SYSTEMS AND SOFTWARE SOLUTIONS " className = "container-fluid">
        <h2>New Arrival</h2>
        <div className = "row">
        {productByArrival.map((product,i) => {
                return(
            <div key = {i} className = "col-12 mb-3 col-lg-4 col-md-4 col-xl-4">
            <Card  product = {product} />
            </div>
                );
            })}
        </div>
        <br></br>
        <h2>Best Sellers</h2>
        <div className = "row">
        {productBySell.map((product,i) => {
                return(
            <div key = {i} className = "col-12 mb-3 col-lg-4 col-md-4 col-xl-4">
            <Card  product = {product}/>
            </div>
                );
            })}
        </div>
    </Layout>)

    }


    </div>
);
}
export default Home; 